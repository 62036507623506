//components
import Link from '../Link';

const Fiction = () => {
    return (
        <div className='page'>
            <div className='hero flex-row blue'>
                <article className='hero-title'>
                    <h2>UNION CHRONICLES.</h2>
                    <Link href='/fiction/union-chronicles' className='centered ma'>read</Link>
                </article>
                <div className='hero-content'>
                    <p>
                        <span className="drop-cap">U</span>NION CHRONICLES is a captivating story set in the University of Alethea, following the lives of two engineering students. Artero, a prodigy from a humble background, excels effortlessly in his studies. Santi, while also talented, occasionally struggles with complex subjects like calculus. The narrative explores their friendship, academic challenges, and personal growth through engaging study sessions and competitions with their friends Xander and Olive.

                        As the story progresses, we see how their experiences in university shape their futures. Artero eventually leaves his postgraduate studies to found a successful tech startup, while Santi completes her postgraduate work and collaborates with data scientists on government-funded research.

                        The book also introduces Percival, a character who initially struggles academically but finds his passion later in life. His journey from studying history to discovering his aptitude for mathematics and data science serves as a powerful narrative about personal growth, second chances, and the interconnectedness of different fields of study.

                        Through these intertwining stories, the novel explores themes of friendship, academic pressure, personal discovery, and the various paths to success. It highlights how one's background, mindset, and experiences can shape their journey through education and beyond.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Fiction;
