//libs
import React from 'react';

const Research = () => {
    return (
        <div className='page'>
            <div className='hero flex-row light'>
                <article className='hero-title'>
                    <h2>ALEXANDRiiiA.</h2>
                    <a href='https://www.kaggle.com/datasets/babekizulu/alexandriiia' className='centered ma' target='_blank' rel='noreferrer'>
                        view
                    </a>
                </article>
                <div className='hero-content'>
                    <p>ALEXANDRiiiA is an innovative project that leverages the power of historical data to create accurate narrative reconstructions of past events. By employing advanced inference techniques and machine learning algorithms, ALEXANDRiiiA transforms raw historical facts into nuanced, coherent narratives. This sophisticated approach allows for a deeper understanding of historical contexts and interconnections. The project has wide-ranging potential applications, particularly in the fields of education and formal academic research. In educational settings, ALEXANDRiiiA can provide students with immersive, data-driven historical narratives, enhancing their understanding and engagement with the past. For academic researchers, it offers a powerful tool to analyze and interpret large volumes of historical data, potentially uncovering new insights and connections in their fields of study.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Research;
