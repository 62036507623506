//libs
import React from 'react';

const LogoSVG = () => {
    return (
        <div className='logo-container'>
           <svg width="344" height="50" viewBox="0 0 566 70" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M254.008 6.05883C253.947 6.55311 250.347 9.21133 249.713 9.92437C248.395 11.4073 249.478 16.9736 250.143 18.4286C252.774 24.1838 258.354 23.1471 260.795 17.6555C262.673 13.4286 260.622 8.56621 257.702 6.23063C253.709 3.03622 253.076 13.0438 255.726 15.1643C258.048 17.0216 258.722 11.9663 258.647 10.6975C258.598 9.85748 257.541 9.01058 256.714 9.19421C254.55 9.67516 254.782 12.3058 254.782 13.9617C254.782 16.0836 254.322 18.6558 255.984 20.3184C258.236 22.5698 259.468 20.5485 259.42 18.085C259.337 13.7365 253.827 10.3646 254.008 16.5388C254.135 20.8357 259.192 13.2855 252.634 13.7899C250.39 13.9625 252.255 18.876 252.849 19.9318C254.308 22.5256 257.187 16.5938 252.978 15.0784C251.931 14.7016 254.369 19.3824 254.739 16.7965C254.808 16.3075 254.049 11.3752 253.407 12.5873C250.072 18.8862 258.9 15.849 254.782 13.7899" stroke="#FFBB38" strokeWidth="2.31933" strokeLinecap="round"/>
<path d="M281.067 8.37816C276.526 8.37816 275.655 14.2756 275.655 18.042C275.655 20.1797 280.336 29.3522 282.613 24.2269C283.947 21.2257 286.528 17.7435 284.546 14.1765C284.171 13.5013 283.469 10.1135 282.957 9.96733C280.685 9.31803 279.521 8.57489 278.361 11.127C277.426 13.1848 278.589 17.326 279.693 19.2017C280.853 21.1735 281.113 14.5725 281.067 13.7899C280.931 11.4731 278.748 12.1661 278.748 14.563C278.748 16.1188 278.197 23.4967 281.067 23.4967C284.22 23.4967 281.067 9.17331 281.067 18.4286C281.067 21.3587 282.613 22.4959 282.613 18.6004C282.613 15.831 283.481 13.7899 280.294 13.7899" stroke="#FF5959" strokeWidth="2.31933" strokeLinecap="round"/>
<path d="M305.034 7.60504C298.795 7.60504 301.884 20.3487 306.966 21.4781C316.036 23.4936 314.62 11.6641 310.445 6.44537C309.506 5.27101 305.36 4.29825 304.26 5.67227C301.952 8.55829 301.941 10.0947 301.941 13.9617C301.941 16.5153 307.625 21.9615 308.083 18.2997C308.22 17.2048 308.806 12.1045 307.697 11.4706C305.826 10.4018 304.851 11.2648 304.432 13.3604C303.183 19.6074 311.203 21.1026 309.5 13.0168C309.012 10.6947 307.264 8.09022 307.353 11.986C307.376 12.9909 309.181 19.6551 310.789 15.9804C311.378 14.6349 312.164 8.88888 310.059 8.4211C307.023 7.74637 307.668 13.8734 309.071 15.1643C311.872 17.7413 310.666 9.09718 309.071 10.8693C307.674 12.4219 310.679 18.6238 311.218 17.1401C311.67 15.8976 312.981 11.9912 310.832 11.5135C308.931 11.0912 309.4 16.2801 310.102 17.183C311.76 19.315 310.153 7.32293 306.58 10.6975C304.634 12.5352 304.921 14.9304 306.58 16.8824C309.001 19.7307 307.725 14.4944 306.966 13.1457C306.184 11.7549 304.012 11.5999 305.42 13.7899C306.165 14.9479 307.935 17.2642 308.899 15.3361" stroke="#8CD6FF" strokeWidth="2.31933" strokeLinecap="round"/>
<path d="M237 46.2605C238.121 46.136 238.738 45.2718 239.534 44.5425C243.748 40.6798 247.787 36.6993 251.689 32.5163C252.982 31.1304 254.991 26.4848 255.512 28.3072C256.264 30.9404 255.555 34.8708 255.555 37.5415C255.555 44.9147 255.555 52.2879 255.555 59.6611C255.555 61.2795 255.845 61.5831 256.929 62.6676C264.005 69.7438 269.367 58.0437 271.06 52.4024C272.221 48.5301 275.891 43.55 277.975 39.9038C278.706 38.6248 278.788 35.5595 279.908 34.6639C280.776 33.9689 280.294 38.6622 280.294 39.732C280.294 45.5466 281.095 51.9643 282.656 57.4276C283.09 58.9445 283.621 62.3012 284.761 63.4407C288.21 66.8898 292.422 60.47 293.824 58.2437C297.901 51.7675 301.588 45.4405 304.862 38.5294C306.028 36.0678 306.58 27.8168 306.58 30.5406C306.58 34.9674 307.46 39.3743 307.954 43.7694C308.367 47.4364 307.986 52.6911 310.016 55.8814C311.241 57.8067 311.156 62.4227 313.967 63.226C324.041 66.1042 320.702 48.5798 329 48.5798" stroke="#DCDCDC" strokeWidth="6" strokeLinecap="round"/>
</svg>
        </div>
    );
};

export default LogoSVG;
