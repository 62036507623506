//components
import Link from './Link';
//icons
import { IoHomeOutline } from 'react-icons/io5';
import { RiLightbulbFlashLine } from 'react-icons/ri';
import { FaConnectdevelop } from 'react-icons/fa';
import { GiSpellBook } from 'react-icons/gi';
import { GrArticle } from 'react-icons/gr';
import { MdWorkOutline } from 'react-icons/md';
import { BsPeople } from 'react-icons/bs';

const Tray = () => {
    const currentPath = window.location.pathname;
    
    return (
        <nav className='tray'>
            <ul style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', padding: '1rem'}}>
                <li>
                    <Link href="/" className={currentPath === '/' ? 'nav-item active' : 'nav-item'} style={{display: 'flex', alignItems: 'center'}}>
                        <span style={{marginRight: '1rem'}}>Home</span>
                        <IoHomeOutline className='icon'/>
                    </Link>
                </li>
                <li>
                    <Link href='/research' className={currentPath === '/research' ? 'nav-item active' : 'nav-item'} style={{display: 'flex', alignItems: 'center'}}>
                        <span style={{marginRight: '1rem'}}>Research</span>
                        <RiLightbulbFlashLine className='icon'/>
                    </Link>
                </li>
                <li>
                    <Link href='/development' className={currentPath === '/development' ? 'nav-item active' : 'nav-item'} style={{display: 'flex', alignItems: 'center'}}>
                        <span style={{marginRight: '1rem'}}>Development</span>
                        <FaConnectdevelop className='icon'/>
                    </Link>
                </li>
                <li>
                    <Link href='/careers' className={currentPath === '/careers' ? 'nav-item active' : 'nav-item'} style={{display: 'flex', alignItems: 'center'}}>
                        <span style={{marginRight: '1rem'}}>Careers</span>
                        <MdWorkOutline className='icon'/>
                    </Link>
                </li>
                <li>
                    <Link href='/fiction' className={currentPath === '/fiction' ? 'nav-item active' : 'nav-item'} style={{display: 'flex', alignItems: 'center'}}>
                        <span style={{marginRight: '1rem'}}>Fiction</span>
                        <GiSpellBook className='icon'/>
                    </Link>
                </li>
                <li>
                    <Link href='/essays' className={currentPath === '/essays' ? 'nav-item active' : 'nav-item'} style={{display: 'flex', alignItems: 'center'}}>
                        <span style={{marginRight: '1rem'}}>Essays</span>
                        <GrArticle className='icon'/>
                    </Link>
                </li>
                <li>
                    <Link href='/team' className={currentPath === '/team' ? 'nav-item active' : 'nav-item'} style={{display: 'flex', alignItems: 'center'}}>
                        <span style={{marginRight: '1rem'}}>Team</span>
                        <BsPeople className='icon'/>
                    </Link>
                </li>
            </ul>
        </nav>
    )
}

export default Tray;