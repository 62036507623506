import { FaLinkedin } from 'react-icons/fa';

function Team() {
    return (
        <div className='page'>
            <div className='hero flex-row orange'>
                <article className='hero-title'>
                    <h2>team.</h2>
                </article>
                <div className='hero-content'>
                    <ul>
                        <li>
                            <h3>Lwandle Babekizulu Dlamini</h3>
                            <p>Founder, CTO</p>
                            <a href='https://www.linkedin.com/in/lwandle-dlamini-a0b1b1222/' target='_blank' rel='noreferrer' style={{display: 'flex', alignItems: 'center', gap: '0.5rem', textDecoration: 'none'}}>LinkedIn <FaLinkedin /></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Team;