function Careers() {
    return (
        <div className='page'>
            <div className='hero flex-row orange'>
                <article className='hero-title'>
                    <h2>careers.</h2>
                </article>
                <div className='hero-content'>
                    <p>While we're not currently hiring, we encourage historians, data analysts, machine learning engineers and fullstack developers to keep an eye on this page for future opportunities.</p>
                </div>
            </div>
        </div>
    )
}

export default Careers;