function Essay3() {
    return (
        <div className='page'>
            <div className='hero flex-row light'>
                <article className='hero-title'>
                    <h2>Conjunction.</h2>
                </article>
                <div className='hero-content'>
                    <p>
                        To the extent that we’re limited by our human nature to a relatively infinitesimal moment of time in history, the problem of achieving our long-term aspirations becomes a frustrating one. When these aspirations are written down, and broken down into smaller components, we come up with a somewhat more manageable framework for accomplishing the task, but even in the successful execution of our frameworks we don’t solve for the problem of time. What if there were a way to open source our ideas and aspirations, and algorithmically align with others who share similar goals, in order to foster collaborations between like-minded individuals. Social networks already solved this problem in part, through social graphs and algorithms that connect us with people who are likely to share the same ideas with us, but perhaps a platform more singularly dedicated to the task of open source ideation and problem sharing could help us expedite the process of realizing an idea. Perhaps we could begin by generating matches based on geographical proximity and similarity of ideas and use the data we retrieve from users to create a global map of ideas to better understand the distribution of ideas, the types of ideas that are more prevalent in a particular region and the environments in which these ideas are being generated. The second step would be generating the optimal strategy for matching collaborators and for executing the ideas generated. To protect our ideas we could integrate smart contracts into the platform and create digital agreements between parties regarding their ideas before the ideas are shared. Just a thought - let me know what you think.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Essay3;