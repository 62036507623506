//components
import Link from '../Link';
//videos
import video1 from '../video/ALEXANDRiiiA.mp4';
const Home = () => {
    return (
        <div className='page'>
            <div className='hero flex-column light' id='top'>
                <h2 className='centered-title'><span>we.</span> <span>learn.</span> <span>differently.</span></h2>
            </div>
            <div className='hero flex-row light'>
                <video 
                    className='video' 
                    autoPlay 
                    loop 
                    muted 
                    playsInline
                >
                    <source src={video1} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className='hero flex-row light'>
                <article className='hero-title'>
                    <h2>research.</h2>
                    <Link href='/research' className='centered ma'>
                        view
                    </Link>
                </article>
                <div className='hero-content'>
                    <p>We specialize in developing innovative tools and technologies that bridge the gap between historical research and modern education. Our focus is twofold: creating sophisticated analysis tools that empower historians to uncover deeper insights from historical data, and designing immersive educational technology that makes learning history a more engaging and interactive experience. Through this unique combination, we're revolutionizing how history is both studied and taught.</p>
                </div>
            </div>
            <div className='hero flex-row orange'>
                    <div className='hero-content'>
                        <p>Our development efforts focus on creating advanced historical analysis tools powered by cutting-edge algorithms and natural language processing. Through ALEXANDRiiiA, we're building sophisticated systems that can extract meaningful insights from historical texts and documents. With an emphasis on accessibility, our user-friendly interfaces make it easy for researchers and history enthusiasts to explore and interpret complex historical data through data-driven analysis.</p>
                    </div>
                    <article className='hero-title'>
                        <h2>development.</h2>
                        <Link href='/development' className='centered ma'>
                            view
                        </Link>
                    </article>
                </div>
                <div className='hero flex-row red'>
                    <article className='hero-title'>
                        <h2>careers.</h2>
                        <Link href='/careers' className='centered ma'>
                            view
                        </Link>
                    </article>
                    <div className='hero-content'>
                        <p>We're always on the lookout for talented individuals who are passionate about history and technology. Whether you're a developer, researcher, or educator, we offer exciting opportunities to work on cutting-edge projects and contribute to our mission of revolutionizing historical education. Join us in our journey to make history more accessible and engaging for everyone.</p>
                    </div>
                </div>
                <div className='hero flex-row blue'>
                    <div className='hero-content'>
                        <p>Our fiction division explores the intersection of history and storytelling through immersive narratives. We're dedicated to creating thought-provoking stories that blend historical facts with imaginative elements, offering readers a unique perspective on the past. Join us in our journey to make history more accessible and engaging for everyone.</p>
                    </div>
                    <article className='hero-title'>
                        <h2>fiction.</h2>
                        <Link href='/fiction' className='centered ma'>
                            view
                        </Link>
                    </article>
                </div>
                <div className='hero flex-row light'>
                    <article className='hero-title'>
                        <h2>essays.</h2>
                        <Link href='/essays' className='centered ma'>
                            view
                        </Link>
                    </article>
                    <div className='hero-content'>
                        <p>Our essays section features in-depth writings that document and explore the journey of our research and development process. These pieces offer valuable insights into our methodologies, challenges, and breakthroughs while working on various projects. From detailed analyses of historical data processing techniques to reflections on developing educational tools, these essays provide a transparent look into how we're advancing the field of historical research and education technology.</p>
                    </div>
                </div>
                <div className='hero flex-row orange'>
                    <div className='hero-content'>
                        <p>We're trying to build a team of like-minded individuals who are passionate about history and technology. If you're interested in joining us, please reach out to us using the form below or through our contact information.</p>
                    </div>
                    <article className='hero-title'>
                        <h2>team.</h2>
                        <Link href='/team' className='centered ma'>
                            view
                        </Link>
                    </article>
                </div>
        </div>
    )
}

export default Home;
