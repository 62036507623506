//components
import Header from './components/Header';
import Route from './components/Route';
import Tray from './components/Tray';
//pages
import Home from './components/pages/Home';
import Research from './components/pages/Research';
import Development from './components/pages/Development';
import Careers from './components/pages/Careers';
import Fiction from './components/pages/Fiction';
import Essays from './components/pages/Essays';
import UnionChronicles from './components/pages/fiction/UnionChronicles';
import Essay1 from './components/pages/essays/Essay1';
import Essay2 from './components/pages/essays/Essay2';
import Essay3 from './components/pages/essays/Essay3';
import Team from './components/pages/Team';
//styling
import './scss/App.scss';
const App = () => {
    return (
        <div className='app-container'>
            <Header/>
            <Route path="/">
                <Home/>
            </Route>
            <Route path="/research">
                <Research/>
            </Route>
            <Route path="/development">
                <Development/>
            </Route>
            <Route path="/careers">
                <Careers/>
            </Route>
            <Route path="/fiction">
                <Fiction/>
            </Route>
            <Route path="/essays">
                <Essays/>
            </Route>
            <Route path="/team">
                <Team/>
            </Route>
            <Route path="/fiction/union-chronicles">
                <UnionChronicles/>
            </Route>
            <Route path="/essays/weaving-history">
                <Essay1/>
            </Route>
            <Route path="/essays/branching-out">
                <Essay2/>
            </Route>
            <Route path="/essays/conjunction">
                <Essay3/>
            </Route>
            <Tray/>
        </div>
    )
};

export default App;
