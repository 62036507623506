function Essay2() {
    return (
        <div className='page'>
            <div className='hero flex-row light'>
                <article className='hero-title'>
                    <h2>Branching Out</h2>
                </article>
                <div className='hero-content'>
                    <p>
                        I'm convinced we should all be historians - I hope this post will qualify this statement. Lately I've been exploring the different ways we can understand our history and interface with it - interact with it in tangible and intuitive ways. Why on Earth would we want to interface with history? Well because history tells us a story of constraints and actions taken to resolve those constraints, or at least to live more comfortably within them. Technological history points to this most poignantly, as we see how the development of technology has allowed us to overcome constraints in ways that were previously unimaginable.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Essay2;